import EntryProxy from '@/proxies/EntryProxy';
import MailDomainProxy from '@/proxies/MailDomainProxy';

export default {
    namespaced: true,
    state: {
        entry: {
        }
    },

    getters: {
        get: state => ( values ) =>
        {
            if( values )
            {
                let data = { values } = state.entry;
                return data;
            }
            return state.entry;
        },
        all: state => state.entry
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .create( context.getters.get() )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        checkMailDomain: ( context, payload ) =>
        {
            // payload = { emailaddress: <emailaddress> }

            return new MailDomainProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return error;
                    // return Promise.reject( error );
                });
        }
    }
};
